<template>
  <div class="service-setting">
    <txt :type="TxtType.Heading3">
      {{ title }}
    </txt>
    <txt
      v-if="maleExist && femaleExist"
      :type="TxtType.Heading4"
      class="service-setting__subtitle"
    >
      {{ $t('pages.serviceSettings.forMen') }}
    </txt>
    <div
      v-if="maleExist"
      class="service-setting__wrapper"
    >
      {{ $t('pages.serviceSettings.price', { currency: currencySign }) }}
      <number-input
        :model-value="serviceSettingsMap[model.costMale]"
        :step="50"
        @update:modelValue="$emit('update', $event, model.costMale)"
      />
    </div>
    <div
      v-if="maleExist"
      class="service-setting__wrapper"
    >
      {{ durationText }}
      <number-input
        :type="type"
        :model-value="serviceSettingsMap[model.timeMale]"
        @update:modelValue="$emit('update', $event, model.timeMale)"
      />
    </div>

    <txt
      v-if="maleExist && femaleExist"
      :type="TxtType.Heading4"
      class="service-setting__subtitle"
    >
      {{ $t('pages.serviceSettings.forWomen') }}
    </txt>

    <div
      v-if="femaleExist"
      class="service-setting__wrapper"
    >
      {{ $t('pages.serviceSettings.price', { currency: currencySign }) }}
      <number-input
        :model-value="serviceSettingsMap[model.costFemale]"
        :step="50"
        @update:modelValue="$emit('update', $event, model.costFemale)"
      />
    </div>
    <div
      v-if="femaleExist"
      class="service-setting__wrapper"
    >
      {{ durationText }}
      <number-input
        :type="type"
        :model-value="serviceSettingsMap[model.timeFemale]"
        @update:modelValue="$emit('update', $event, model.timeFemale)"
      />
    </div>
  </div>
</template>

<script lang="ts" src="./ServiceSetting.ts" />
