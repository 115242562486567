import { defineComponent, reactive, watch } from 'vue';
import { useRouter } from 'vue-router';
import { MainColor, Size } from 'core/styles';
import { IconName, IconType } from 'components/Icon';
import { useCurrency } from 'composables/currency';
import { IServiceSettings, ServiceSettingsSubject } from 'api/adminService';
import { useStore } from 'vuex';
import { APP_STORE_KEY, AppGetter, AppMutation } from 'store/app';
import { ADMIN_STORE_KEY, AdminAction } from 'store/admin';
import { ServiceNames } from 'composables/serviceSettings';
import { TxtType } from 'components/Txt';
import { useApiErrors } from 'composables/apiErrors';
import { RouteNames } from 'router/names';
import NumberInput from './components/NumberInput';
import ServiceSetting from './components/ServiceSetting';

const ServiceSettings = defineComponent({
  name: RouteNames.AdminPanelServiceSettings,
  components: {
    NumberInput,
    ServiceSetting,
  },
  setup() {
    const { currencySign } = useCurrency();
    const { back } = useRouter();
    const store = useStore();
    const { showError } = useApiErrors();

    const serviceSettingsMap = reactive<Record<ServiceSettingsSubject, number>>(
      Object.values(ServiceSettingsSubject).reduce((acc: any, value) => {
        acc[value] = 0;

        return acc;
      }, {}),
    );

    function fillServiceMap(value: IServiceSettings[]) {
      if (!value) return;
      value.forEach((serviceSetting) => {
        serviceSettingsMap[serviceSetting.subject] = parseInt(serviceSetting.value, 10);
      });
    }
    fillServiceMap(store.getters[`${APP_STORE_KEY}/${AppGetter.ServiceSettings}`]);
    watch(() => store.getters[`${APP_STORE_KEY}/${AppGetter.ServiceSettings}`], fillServiceMap, { deep: true });

    function update(value: number, subject: string) {
      const setting = store.getters[`${APP_STORE_KEY}/${AppGetter.ServiceSettingValue}`](subject);
      if (!setting) return;
      serviceSettingsMap[subject] = value;

      store.dispatch(`${ADMIN_STORE_KEY}/${AdminAction.UpdateServiceSetting}`, {
        id: setting.id,
        value,
      }).then(() => {
        store.commit(`${APP_STORE_KEY}/${AppMutation.UpdateSetting}`, {
          subject,
          value,
        });
      }).catch((e) => showError(e));
    }

    return {
      back,
      currencySign,
      serviceSettingsMap,
      update,
      ServiceNames,
      ServiceSettingsSubject,

      Size,
      IconType,
      IconName,
      MainColor,
      TxtType,
    };
  },
});

export default ServiceSettings;
