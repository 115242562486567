import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

export function useCurrency() {
  const { n } = useI18n();

  const currencySign = computed(() => {
    const str = n(0, 'currency');
    return str[str.length - 1];
  });

  function formatCurrency(amount: number, withSign = false, floor = true) {
    const value = floor ? Math.floor(amount) : amount;

    const formatted = n(value, 'currency');

    if (withSign) {
      if (value < 0) {
        return `- ${n(Math.abs(value), 'currency')}`;
      }
      if (value > 0) {
        return `+ ${formatted}`;
      }
    }

    return formatted;
  }

  return {
    formatCurrency,
    currencySign,
  };
}
