import { computed, defineComponent, ref, watch } from 'vue';
import { IconName, IconType } from 'components/Icon';
import { MainColor, Size } from 'core/styles';

export const NumberInput = defineComponent({
  props: {
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 999999,
    },
    step: {
      type: Number,
      default: 1,
    },
    type: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Number,
      default: 0,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const multiplier = computed(() => {
      if (props.type === 'h') return 60; // backend requires min
      if (props.type === 'd') return 60 * 24;
      return 1;
    });

    const model = ref<number>(Math.round(props.modelValue / multiplier.value));
    let prevModel = 0;

    function updateModel(value: string | number) {
      let parsedValue;
      if (typeof value === 'string') parsedValue = parseInt(value, 10);
      else parsedValue = value;

      if (parsedValue === prevModel) return;

      model.value = parsedValue;
      prevModel = model.value;
      emit('update:modelValue', model.value * multiplier.value);
    }

    watch(() => props.modelValue, (value: number) => {
      model.value = Math.round(props.modelValue / multiplier.value);
    });

    function onKeyPress(event: KeyboardEvent) {
      if (event.key < '0' || event.key > '9') {
        event.preventDefault();
      }

      const num = parseInt(model.value + event.key, 10);

      if (num > props.max) {
        event.preventDefault();
        model.value = props.max;
      }
      if (num < props.min) {
        event.preventDefault();
        model.value = props.min;
      }
    }

    function increment() {
      let value = model.value + props.step;
      if (value > props.max) value = props.max;

      updateModel(value);
    }
    function decrement() {
      let value = model.value - props.step;
      if (value < props.min) value = props.min;

      updateModel(value);
    }

    return {
      model,
      updateModel,

      increment,
      decrement,
      onKeyPress,

      IconName,
      IconType,
      MainColor,
      Size,
    };
  },
});

export default NumberInput;
