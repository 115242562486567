import { computed, defineComponent } from 'vue';
import { TxtType } from 'components/Txt';
import { useCurrency } from 'composables/currency';
import { PropType } from '@vue/runtime-core';
import { ServiceNames } from 'composables/serviceSettings';
import { ServiceSettingsSubject } from 'api/adminService';
import { useI18n } from 'vue-i18n';
import NumberInput from '../NumberInput';

const ServiceSetting = defineComponent({
  components: {
    NumberInput,
  },
  props: {
    name: {
      type: String as PropType<ServiceNames>,
      required: true,
    },
    serviceSettingsMap: {
      type: Object as PropType<Record<ServiceSettingsSubject, number>>,
      required: true,
    },
    type: {
      type: String,
      default: '',
    },
  },
  emits: ['update'],
  setup(props) {
    const { t } = useI18n();
    const { currencySign } = useCurrency();

    const durationText = computed(() => {
      if (props.type === 'h') return t('pages.serviceSettings.publishDuration');
      if (props.type === 'd') return t('pages.serviceSettings.period');
      return '';
    });

    const model = computed(() => ({
      costMale: ServiceSettingsSubject[`${props.name}CostMale`],
      costFemale: ServiceSettingsSubject[`${props.name}CostFemale`],
      timeMale: ServiceSettingsSubject[`${props.name}TimeMale`],
      timeFemale: ServiceSettingsSubject[`${props.name}TimeFemale`],
    }));

    const femaleExist = computed(
      () => props.serviceSettingsMap[model.value.timeFemale] ?? props.serviceSettingsMap[model.value.costFemale],
    );

    const maleExist = computed(
      () => props.serviceSettingsMap[model.value.timeMale] ?? props.serviceSettingsMap[model.value.costMale],
    );

    const title = computed(() => {
      const value = props.serviceSettingsMap[model.value.timeMale] || props.serviceSettingsMap[model.value.timeFemale];
      if (value) t(`pages.serviceSettings.${props.name}`);
      let multiplier = 1;
      if (props.type === 'h') multiplier = 60; // backend requires min
      if (props.type === 'd') multiplier = 60 * 24;
      return t(`pages.serviceSettings.${props.name}`, { n: value / multiplier });
      });

    return {
      title,
      model,
      currencySign,
      durationText,
      ServiceSettingsSubject,
      maleExist,
      femaleExist,

      TxtType,
    };
  },
});

export default ServiceSetting;
