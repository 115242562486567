<template>
  <div class="number-input">
    <icon-btn
      class="number-input__btn"
      :size="Size.Xxs"
      @click="decrement"
    >
      <icon
        :name="IconName.Minus"
        :type="IconType.Contour"
        :color="MainColor.White"
        :size="Size.Xxs"
      />
    </icon-btn>
    <input
      :value="model"
      class="number-input__input"
      inputmode="numeric"
      max="123"
      min="0"
      @blur="updateModel($event.target.value)"
      @keypress="onKeyPress"
    />
    <icon-btn
      class="number-input__btn"
      :size="Size.Xxs"
      @click="increment"
    >
      <icon
        :name="IconName.Plus"
        :type="IconType.Contour"
        :color="MainColor.White"
        :size="Size.Xxs"
      />
    </icon-btn>
  </div>
</template>

<script lang="ts" src="./NumberInput.ts" />
<style lang="scss" src="./numberInput.scss" />
