<template>
  <div class="service-settings">
    <div class="service-settings-header">
      <h4>{{ $t('pages.serviceSettings.title') }}</h4>
      <icon-btn
        :size="Size.L"
        @click="back"
      >
        <icon
          :type="IconType.Contour"
          :name="IconName.ArrowBack"
          :size="Size.S"
          :color="MainColor.Black"
        />
      </icon-btn>
    </div>
    <main class="service-settings__content">

      <service-setting
        :name="ServiceNames.TakeMeToTheTop"
        :service-settings-map="serviceSettingsMap"
        type="d"
        class="service-settings__setting"
        @update="update"
      />

      <service-setting
        :name="ServiceNames.Subscription"
        :service-settings-map="serviceSettingsMap"
        type="d"
        class="service-settings__setting"
        @update="update"
      />

      <service-setting
        :name="ServiceNames.Highlight"
        :service-settings-map="serviceSettingsMap"
        type="h"
        class="service-settings__setting"
        @update="update"
      />

      <service-setting
        :name="ServiceNames.MessageSubscriptionSmall"
        :service-settings-map="serviceSettingsMap"
        type="d"
        class="service-settings__setting"
        @update="update"
      />

      <service-setting
        :name="ServiceNames.MessageSubscriptionMedium"
        :service-settings-map="serviceSettingsMap"
        type="d"
        class="service-settings__setting"
        @update="update"
      />

      <service-setting
        :name="ServiceNames.MessageSubscriptionMax"
        :service-settings-map="serviceSettingsMap"
        type="d"
        class="service-settings__setting"
        @update="update"
      />

      <txt :type="TxtType.Heading3">
        {{ $t(`pages.serviceSettings.${ServiceNames.RemoveAllMessages}`) }}
      </txt>
      <div
        class="service-setting__wrapper"
      >
        {{ $t('pages.serviceSettings.price', { currency: currencySign }) }}
        <number-input
          :model-value="serviceSettingsMap[ServiceSettingsSubject[`${ServiceNames.RemoveAllMessages}Cost`]]"
          :step="50"
          @update:modelValue="update($event, ServiceSettingsSubject[`${ServiceNames.RemoveAllMessages}Cost`])"
        />
      </div>
    </main>
  </div>
</template>

<script lang="ts" src="./ServiceSettings.ts" />
<style lang="scss" src="./serviceSettings.scss" />
